import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { routing } from './app.routing';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { HttpClientModule } from '@angular/common/http';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { Config } from './shared/config';
import { LoginComponent } from './login/login.component';
import { InitService } from './shared/init.service';
import { UserService } from './shared/user.service';
import { KadoService } from './shared/kado.service';
import { APP_INITIALIZER } from '@angular/core';
import { MainComponent } from './main/main.component';
import { PrimengModule } from './primeng.module';
import { AanmeldenComponent } from './aanmelden/aanmelden.component';
import { HeaderComponent } from './header/header.component';
import { MijnlijstComponent } from './mijnlijst/mijnlijst.component';
import { KiesComponent } from './kies/kies.component';
import { AddRowDirective } from './shared/add-row.directive';
import { BoodschappenlijstComponent } from './boodschappenlijst/boodschappenlijst.component';

export function initServiceFactory(init: InitService) {
  return () => init.initialize();
}

@NgModule({
  declarations: [
    AppComponent,
    LoginComponent,
    MainComponent,
    AanmeldenComponent,
    HeaderComponent,
    MijnlijstComponent,
    AddRowDirective,
    KiesComponent,
    BoodschappenlijstComponent,
  ],
  imports: [
    BrowserModule,
    PrimengModule,
    BrowserAnimationsModule,
    AppRoutingModule,
    routing,
    FormsModule,
    ReactiveFormsModule,
    HttpClientModule,
  ],
  providers: [
    Config,
    InitService,
    UserService,
    KadoService,
    {
      provide: APP_INITIALIZER,
      useFactory: initServiceFactory,
      deps: [InitService],
      multi: true,
    },
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
