import { Component, OnInit } from '@angular/core';
import {
  FormGroup,
  FormControl,
  Validators,
  FormBuilder,
  FormArray,
  ValidationErrors,
  AbstractControl,
} from '@angular/forms';
import { Router, ActivatedRoute } from '@angular/router';
import { MessageService } from 'primeng/api';
import { UserService } from '../shared/user.service';
// import { ApplicationService } from "../shared/application.service";

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss'],
})
export class LoginComponent implements OnInit {
  myForm: FormGroup;
  aanmeldForm: FormGroup;
  errorMessage: string;
  dspAanmelden: boolean = false;
  dspWachtwoordVergeten: boolean = false;
  dspNieuwWachtwoord: boolean = false;
  email: string;
  token: string;
  wachtwoord: string;
  controle: string;
  userid: string;

  constructor(
    public _router: Router,
    public _route: ActivatedRoute,
    public _formbuilder: FormBuilder,
    public _userservice: UserService, // public _applicationservice: ApplicationService
    public messageService: MessageService
  ) {}

  ngOnInit() {
    // this._applicationservice.showNavbar = false;
    this.myForm = this._formbuilder.group({
      username: ['', Validators.required],
      password: ['', Validators.required],
    });

    this.aanmeldForm = this._formbuilder.group({
      loginName: ['', Validators.required],
      email: ['', Validators.required],
      password: ['', Validators.required],
      passwordCheck: ['', [Validators.required, this.matchValues('password')]],
    });

    var reset = this._route.snapshot.queryParamMap.get('reset');
    console.log(1);
    console.log(window.location.href);
    console.log(this._route.url);
    if (reset > '') {
      console.log('hoppa');
      this.token = reset;
      this.resetWachtwoord();
    }
  }

  onSubmitx() {
    this._userservice
      .signin(this.myForm.value.username, this.myForm.value.password)
      .subscribe(
        (data: any) => {
          console.log('Login', data);
          localStorage.setItem('token', data['token']);
          // this._applicationservice.user = data.user || {};
          // this._applicationservice.profile = data.user.profile;
          // this._applicationservice.showNavbar = true;
          this.errorMessage = null;
          this._router.navigate(['/main']);
        },
        (error) => {
          this.errorMessage = 'Email  niet gevonden of wachtwoord verkeerd';
          console.error(error);
        }
      );
  }

  onSubmit() {
    console.log('Submit !!!!');
    this._userservice
      .signin(this.myForm.value.username, this.myForm.value.password)
      .subscribe({
        next: (data) => {
          console.log('Login', data);
          localStorage.setItem('token', data['token']);
          // this._applicationservice.user = data.user || {};
          // this._applicationservice.profile = data.user.profile;
          // this._applicationservice.showNavbar = true;
          this.errorMessage = null;
          this._router.navigate(['/main']);
        },
        error: (e) => {
          this.errorMessage = e.error.error.message;
        },
      });
  }

  aanmelden() {
    this.dspAanmelden = false;

    let values = {};
    // tslint:disable-next-line:forin
    for (let f in this.aanmeldForm.controls) {
      values[f] = this.aanmeldForm.controls[f].value;
    }

    this._userservice.addUser(values).subscribe((result) => {
      console.log(result);
      this.myForm.reset();
    });
  }

  matchValues(
    matchTo: string // name of the control to match to
  ): (AbstractControl) => ValidationErrors | null {
    return (control: AbstractControl): ValidationErrors | null => {
      return !!control.parent &&
        !!control.parent.value &&
        control.value === control.parent.controls[matchTo].value
        ? null
        : { isMatching: false };
    };
  }

  wachtwoordVergeten() {
    this.dspWachtwoordVergeten = false;
    this.errorMessage = null;
    this._userservice.vergetenPassword(this.email).subscribe(
      (result) => {
        console.log(result);
        this.messageService.add({
          severity: 'success',
          summary: 'Aanvraag wachtwoord',
          detail: 'Je krijgt een link om een nieuw wachtwoord aan te maken',
        });
      },
      (error) => {
        this.errorMessage = 'Email niet gevonden';
        console.error(error);
      }
    );
  }

  resetWachtwoord() {
    console.log('reset wachtwoord', this.token);

    this._userservice.checkPasswordToken(this.token).subscribe(
      (result) => {
        if (result['success']) {
          localStorage.setItem('id', result['userId']);
          this.dspNieuwWachtwoord = true;
        }
      },
      (error) => {
        console.error(error);
      }
    );

    this._router.navigate(['/login']);
  }

  nieuwWachtwoord() {
    this._userservice
      .resetPassword(this.wachtwoord, this.controle, localStorage.getItem('id'))
      .subscribe((result) => {
        console.log(result);
        this.messageService.add({
          severity: 'success',
          summary: 'Aanvraag wachtwoord',
          detail: 'Je nieuwe wachtwoord is geactiveerd ',
        });
      });
    this.dspNieuwWachtwoord = false;
  }
}
