import { NgModule } from '@angular/core';

import { TableModule } from 'primeng/table';
import { KeyFilterModule } from 'primeng/keyfilter';
import { ToolbarModule } from 'primeng/toolbar';
import { SlideMenuModule } from 'primeng/slidemenu';
// import { ScheduleModule } from "primeng/schedule";
import { TabViewModule } from 'primeng/tabview';
import { ToastModule } from 'primeng/toast';
// import { GrowlModule } from "primeng/growl";
import { MessageModule } from 'primeng/message';
import { SplitButtonModule } from 'primeng/splitbutton';
// import { SharedModule } from "primeng/components/common/shared";
import { CheckboxModule } from 'primeng/checkbox';
import { DropdownModule } from 'primeng/dropdown';
import { InputTextModule } from 'primeng/inputtext';
import { ProgressSpinnerModule } from 'primeng/progressspinner';
import { AccordionModule } from 'primeng/accordion';
import { ConfirmDialogModule } from 'primeng/confirmdialog';
import { BlockUIModule } from 'primeng/blockui';
import { PanelModule } from 'primeng/panel';
import { MenubarModule } from 'primeng/menubar';
import { TabMenuModule } from 'primeng/tabmenu';
import { SpinnerModule } from 'primeng/spinner';
import { DialogModule } from 'primeng/dialog';
import { FileUploadModule } from 'primeng/fileupload';
import { ListboxModule } from 'primeng/listbox';
import { InputMaskModule } from 'primeng/inputmask';
import { OverlayPanelModule } from 'primeng/overlaypanel';
import { ButtonModule } from 'primeng/button';
import { MultiSelectModule } from 'primeng/multiselect';
import { ToggleButtonModule } from 'primeng/togglebutton';
import { SelectButtonModule } from 'primeng/selectbutton';
import { CalendarModule } from 'primeng/calendar';
import { InputTextareaModule } from 'primeng/inputtextarea';
import { TreeTableModule } from 'primeng/treetable';
import { RadioButtonModule } from 'primeng/radiobutton';
import { MenuModule } from 'primeng/menu';
import { ConfirmationService, SharedModule } from 'primeng/api';
import { CardModule } from 'primeng/card';
import { BadgeModule } from 'primeng/badge';
import { MessagesModule } from 'primeng/messages';
import { MessageService } from 'primeng/api';
import { ChipModule } from 'primeng/chip';

@NgModule({
  imports: [
    // GrowlModule,
    MessageModule,
    ToastModule,
    SplitButtonModule,
    SharedModule,
    CheckboxModule,
    DropdownModule,
    TreeTableModule,
    MenuModule,
    RadioButtonModule,
    InputTextareaModule,
    CalendarModule,
    ToggleButtonModule,
    SelectButtonModule,
    MultiSelectModule,
    ButtonModule,
    OverlayPanelModule,
    InputMaskModule,
    ListboxModule,
    FileUploadModule,
    DialogModule,
    SpinnerModule,
    TabMenuModule,
    MenubarModule,
    BlockUIModule,
    PanelModule,
    ConfirmDialogModule,
    AccordionModule,
    ProgressSpinnerModule,
    TableModule,
    KeyFilterModule,
    InputTextModule,
    ToolbarModule,
    SlideMenuModule,
    // ScheduleModule,
    TabViewModule,
    BadgeModule,
    MessageModule,
    MessagesModule,
    ChipModule,
  ],
  exports: [
    // GrowlModule,
    MessageModule,
    ToastModule,
    SplitButtonModule,
    SharedModule,
    CheckboxModule,
    DropdownModule,
    TreeTableModule,
    MenuModule,
    RadioButtonModule,
    InputTextareaModule,
    CalendarModule,
    ToggleButtonModule,
    SelectButtonModule,
    MultiSelectModule,
    ButtonModule,
    OverlayPanelModule,
    InputMaskModule,
    ListboxModule,
    FileUploadModule,
    DialogModule,
    SpinnerModule,
    TabMenuModule,
    MenubarModule,
    BlockUIModule,
    PanelModule,
    ConfirmDialogModule,
    AccordionModule,
    ProgressSpinnerModule,
    TableModule,
    KeyFilterModule,
    InputTextModule,
    ToolbarModule,
    SlideMenuModule,
    // ScheduleModule,
    TabViewModule,
    CardModule,
    BadgeModule,
    MessageModule,
    MessagesModule,
    ChipModule,
  ],
  providers: [ConfirmationService, MessageService],
})
export class PrimengModule {}
