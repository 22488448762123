import { Component, OnInit } from '@angular/core';
import { Kado } from '../shared/kado';
import { KadoService } from '../shared/kado.service';
import { User } from '../shared/user';

@Component({
  selector: 'app-boodschappenlijst',
  templateUrl: './boodschappenlijst.component.html',
  styleUrls: ['./boodschappenlijst.component.scss'],
})
export class BoodschappenlijstComponent implements OnInit {
  kados: Kado[] = [];
  id = localStorage.getItem('id');
  selectedKado: Kado;

  constructor(public _kadoService: KadoService) {}

  ngOnInit(): void {
    this.getKados();
  }

  getKados() {
    this._kadoService.getBoodschappen(this.id).subscribe((result: Kado[]) => {
      this.kados = result;
    });
  }

  kadoGekocht(id) {
    console.log(id);
    this._kadoService.vulGekocht(id).subscribe((result) => {
      this.getKados();
    });
  }
}
