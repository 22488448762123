<p-menubar [model]="items">
  <ng-template pTemplate="end">
    <div class="flex align-items-center justify-content-start">
      <div style="padding-right: 20px" *ngIf="userService.role == 'admin'">
        <p-checkbox
          name="group1"
          [binary]="true"
          [(ngModel)]="userService.showAdmin"
          inputId="ny"
        ></p-checkbox>
        <label style="padding-left: 10px" for="ny">Admin</label>
      </div>
      <div>
        <button
          type="button"
          pButton
          icon="fal fa-user"
          (click)="menu.toggle($event)"
        ></button>
      </div>
    </div>

    <div>
      <p-menu #menu [popup]="true" [model]="itemsperson"></p-menu>
    </div>
  </ng-template>
</p-menubar>

<p-dialog header="Wachtwoord" [(visible)]="dlgPassword" modal="true">
  <form #f="ngForm">
    <div class="container-fluid">
      <div class="row"></div>

      <div class="row regel">
        <button type="button" pButton (click)="exitPassword()">
          Annuleren
        </button>
        <button type="button" pButton (click)="changePassword()">OK</button>
      </div>
    </div>

    <div class="error"></div>
  </form>
</p-dialog>
