<app-header></app-header>

<p-table
  #dt
  [value]="kados"
  dataKey="_id"
  editMode="row"
  selectionMode="single"
  autoLayout="true"
  [(selection)]="selectedKado"
>
  <ng-template pTemplate="header">
    <tr>
      <th style="display: none"></th>

      <th>Voor</th>
      <th>Omschrijving</th>
      <th>Gekocht</th>
    </tr>
  </ng-template>
  <ng-template
    pTemplate="body"
    let-kado
    let-editing="editing"
    let-ri="rowIndex"
  >
    <tr [pEditableRow]="kado" [pSelectableRow]="kado">
      <td style="display: none">{{ kado._id }}</td>
      <td>{{ kado.naam }}</td>
      <td>
        <a href="{{ kado.url }}" target="_blank">{{ kado.omschrijving }}</a>
      </td>
      <td style="width: 5em">
        <button
          pButton
          pRipple
          type="button"
          icon="{{ kado.gekocht ? 'pi pi-check' : 'pi pi-circle' }}"
          class="p-button-rounded"
          (click)="kadoGekocht(kado._id)"
        ></button>
      </td>
    </tr>
  </ng-template>
</p-table>
