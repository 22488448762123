import { Injectable } from '@angular/core';
import {
  CanActivate,
  ActivatedRouteSnapshot,
  RouterStateSnapshot,
  Router,
} from '@angular/router';
import { UserService } from './shared/user.service';

@Injectable({
  providedIn: 'root',
})
export class AuthGuard implements CanActivate {
  constructor(
    // private authService: AuthService,
    public _router: Router,
    public _userService: UserService
  ) {}

  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): boolean {
    console.log('AuthGuard#canActivate called');
    const token = localStorage.getItem('token');
    console.log('Token: ', token);
    if (!token) {
      this._router.navigate(['/login']);
      return false;
    }
    console.log('Test token');
    this._userService.checktoken(token).subscribe((result) => {
      console.log('Result: ', result);
      if (result['success']) {
        return true;
      } else {
        this._router.navigate(['/login']);
        return false;
      }
    });

    return true;
  }
}
