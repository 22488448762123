<div class="p-fluid p-grid">
  <p-card>
    <div class="p-field p-col-12">
      <span class="p-float-label">
        <input id="firstname1" type="text" pInputText />
        <label for="firstname1">Inlognaam</label>
      </span>
    </div>
    <div class="p-field p-col-12">
      <span class="p-float-label">
        <input id="lastname1" type="text" pInputText />
        <label for="lastname1">Voornaam</label>
      </span>
    </div>
    <div class="p-field p-col-12">
      <span class="p-float-label">
        <input id="lastname1" type="text" pInputText />
        <label for="lastname1">Achternaam</label>
      </span>
    </div>
    <div class="p-field p-col-12">
      <span class="p-float-label">
        <input id="lastname1" type="text" pInputText />
        <label for="lastname1">Email</label>
      </span>
    </div>
    <div class="p-field p-col-12">
      <span class="p-float-label">
        <input id="lastname1" type="text" pInputText />
        <label for="lastname1">Wachtwoord</label>
      </span>
    </div>
    <div class="p-field p-col-12">
      <span class="p-float-label">
        <input id="lastname1" type="text" pInputText />
        <label for="lastname1">Wachtwoord controle</label>
      </span>
    </div>
  </p-card>
</div>
