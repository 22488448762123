import { UserService } from './user.service';
import { Injectable } from '@angular/core';
import { Config } from './config';
import { environment } from '../../environments/environment';

import { Observable } from 'rxjs';

import { HttpClient } from '@angular/common/http';

@Injectable()
export class InitService {
  exit = false;

  constructor(
    public _http: HttpClient,
    public _config: Config,
    public _userService: UserService
  ) {}

  async initialize() {
    console.log('Start initialization');

    await this.loadConfig();

    // await this.checkToken();
    // console.log(this.exit);

    return new Promise((resolve) => {
      resolve(true);
    });
  }

  // checkToken() {
  //   return new Promise<void>(resolve => {
  //     console.log("Check token");
  //     let token = localStorage.getItem("token");
  //     if (token) {
  //       this._userService.checktoken(token).subscribe(
  //         result => {
  //           console.log("test token", result);
  //           if (!result["success"]) {
  //             localStorage.removeItem("token");
  //           }
  //           this.exit = !result["success"];
  //           // localStorage.setItem('token', result['token'])
  //           if (result["user"]) {
  //             this._applicationService.user = result["user"] || {};
  //             this._applicationService.profile = result["user"].profile || {};
  //           }
  //           this._applicationService.authorized = true;
  //           resolve();
  //         },
  //         error => {
  //           console.log("Token invalid");
  //           this._applicationService.authorized = false;
  //           this.exit = true;
  //           resolve();
  //         }
  //       );
  //     } else {
  //       console.log("Token invalid");
  //       this.exit = true;
  //       resolve();
  //     }
  //   });
  // }

  loadConfig() {
    return new Promise<void>((resolve) => {
      console.log('Load config');
      this._config.load().subscribe((env_data) => {
        console.log('Config loaded');
        resolve();
      });
    });
  }
}
