<app-header></app-header>

<h1>Hoe werkt het:</h1>

<p-card
  header="Mijn lijstje:"
  [style]="{
    'margin-bottom': '2em',
    'box-shadow': '0 8px 16px 0 '
  }"
>
  <h3>
    Hier kun je je eigen lijstje samenstellen. <br />Bij elk kado moet je een
    omschrijving opgeven en eventueel een webadres waar het te krijgen is.
    <br />Nadat je een kado toegevoegd hebt, kun je deze nog dezelfde dag
    verwijderen of aanpassen. <br />Daarna is het niet meer mogelijk om te
    voorkomen dat het kado al gekocht is. <br />Denk dus goed na wat je er op
    zet :)<br />
    De kado's die je toevoegd zijn pas de volgende dag te zien voor de andere
    gebruikers. <br />
    Uitzondering is de knop "Maak zichtbaar". Hiermee kun je kado direct
    zichtbaar maken voor de anderen. <br />Je kunt hem dan niet meer aanpassen.
  </h3>
</p-card>
<p-card
  header="Kies:"
  [style]="{ 'margin-bottom': '2em', 'box-shadow': '0 8px 16px 0 ' }"
>
  <h3>
    Hier zie je een lijst van alle andere gebruikers met de kado's op hun
    lijstje die nog niet gekozen zijn en het kado dat jij gekozen hebt. <br />Je
    ziet dus niet de kado's die anderen al gekozen hebben. Je kan eventueel nog
    van kado wisselen. <br />Als er een link is ingegeven bij het kado kun je op
    de naam klikken.<br />Achter de naam van de gebruiker zie je of en hoeveel
    kado's nog beschikbaar zijn.
  </h3>
</p-card>

<p-card
  header="Boodschappenlijst:"
  [style]="{ 'margin-bottom': '2em', 'box-shadow': '0 8px 16px 0 ' }"
>
  <h3>Hier zie je een lijst van alle kados die je gekozen hebt.</h3>
</p-card>
