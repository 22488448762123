export class Kado {
  public omschrijving: string;
  public url: string;
  public aanvrager: string;
  public gever: string;
  public datum: string;
  public naam: string;
  constructor() {
    this.url = '';
    this.omschrijving = '';
    this.aanvrager = '';
    this.gever = '';
    this.datum = '';
    this.naam = '';
  }
}
