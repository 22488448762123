import { Injectable } from '@angular/core';
import { Observable, throwError } from 'rxjs';
// import 'rxjs/rx';
import { Config } from './config';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { catchError, tap } from 'rxjs/operators';
import { ThemeService } from './theme.service';

@Injectable()
export class UserService {
  users: any;
  handleError(arg0: any): any {
    // tslint:disable-next-line:quotemark
    throw new Error(arg0);
  }
  role = '';
  showAdmin = false;

  headers = new HttpHeaders({
    Accept: 'application/json',
    'Content-Type': 'application/json',
    'x-access-token': localStorage.getItem('token'),
  });

  constructor(
    public _config: Config,
    public http: HttpClient,
    private themeService: ThemeService
  ) {}

  signin(username, password) {
    let url = this._config['settings']['db'] + '/api/authenticate';
    console.log(url);

    const options = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        Authorization: 'Basic ' + btoa(username + ':' + password),
      }),
    };

    const body = '';
    return this.http.post(url, body, options).pipe(
      tap((data) => {
        console.log(data);
      })
      // catchError(this.handleErrors)
    );
  }

  checktoken(token) {
    let url = this._config['settings']['db'] + '/api/authenticate/checktoken';

    const options = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        Authorization: localStorage.getItem('token'),
      }),
    };

    let body = { token: token };

    return this.http.post(url, body, options).pipe(
      tap((data) => {
        if (data['success']) {
          console.log('Authenticated');
          console.log(data);
          localStorage.setItem('user', data['user'].loginName);
          localStorage.setItem('id', data['user']._id);
          localStorage.setItem('theme', data['user'].theme);
          this.role = data['user'].role;
          this.themeService.switchTheme(data['user'].theme);
        } else {
          console.log('Not authenticated');
        }
      })
    );
  }

  getUsers() {
    let url = this._config['settings']['db'] + '/api/users';
    console.log(url);
    let options = { headers: this.headers };
    return this.http.get(url, options).pipe(
      tap((data) => {
        console.log(data);
      }),
      catchError(this.handleError)
    );
  }

  getOtherUsers(id) {
    this.headers = new HttpHeaders({
      Accept: 'application/json',
      'Content-Type': 'application/json',
      'x-access-token': localStorage.getItem('token'),
    });
    let url = this._config['settings']['db'] + '/api/users/other?id=' + id;

    let options = { headers: this.headers };
    return this.http.get(url, options).pipe(
      tap((data) => {
        console.log(data);
      }),
      catchError(this.handleError)
    );
  }

  getKados() {
    this.headers = new HttpHeaders({
      Accept: 'application/json',
      'Content-Type': 'application/json',
      'x-access-token': localStorage.getItem('token'),
    });
    let url = this._config['settings']['db'] + '/api/kados';

    let options = { headers: this.headers };
    console.log(options);
    return this.http.get(url, options).pipe(
      tap((data) => {
        console.log(data);
      }),
      catchError(this.handleError)
    );
  }

  addUser(user) {
    console.log('Add new user', user);
    this.headers = new HttpHeaders({
      Accept: 'application/json',
      'Content-Type': 'application/json',
    });
    let url = this._config['settings']['db'] + '/api/newuser';

    let options = { headers: this.headers };
    let body = user;
    console.log(body);

    return this.http.post(url, body, options);
  }

  getUser(key) {
    let url = this._config['settings']['db'] + '/api/users/user/' + key;
    console.log(url);
    let headers = new HttpHeaders();
    // headers.append('Accept', 'application/json');
    // headers.append('Content-Type', 'application/json');
    // headers.append('x-access-token', localStorage.getItem('token'));

    let options = { headers: this.headers };

    return this.http.get(url, options);
  }

  updateUser(id, values) {
    console.log('Update user');
    let url = this._config['settings']['db'] + '/api/users/' + id;
    let headers = new HttpHeaders();
    // headers.append('Accept', 'application/json');
    // headers.append('Content-Type', 'application/json');
    // headers.append('x-access-token', localStorage.getItem('token'));

    let options = { headers: this.headers };
    let body = values;

    this.http.patch(url, body, options).subscribe();
  }

  deleteUser(id) {
    console.log('Verwijder user');
    let url = this._config['settings']['db'] + '/api/users/' + id;
    // this.users.splice(this.users.indexOf(user), 1);
    let headers = new HttpHeaders();
    // headers.append('Accept', 'application/json');
    // headers.append('Content-Type', 'application/json');
    // headers.append('x-access-token', localStorage.getItem('token'));

    let options = { headers: this.headers };
    // let body = JSON.stringify(user);

    this.http.delete(url, options).subscribe((result) => {
      this.getUsers().subscribe();
    });
  }

  resetPassword(pwdcurrent, pwdnew, id) {
    console.log('Change password');
    let url =
      this._config['settings']['db'] + '/api/authenticate/resetpassword';
    let headers = new HttpHeaders();
    headers.append('Accept', 'application/json');
    headers.append('Content-Type', 'application/json');
    headers.append('x-access-token', localStorage.getItem('token'));

    let options = { headers: headers };
    let body = {
      pwdcurrent: pwdcurrent,
      pwdnew: pwdnew,
      id: id,
    };

    return this.http.post(url, body, options);
  }

  vergetenPassword(email) {
    console.log(email);
    let url = this._config['settings']['db'] + '/api/authenticate/sendlink';
    let headers = new HttpHeaders();
    headers.append('Accept', 'application/json');
    headers.append('Content-Type', 'application/json');
    headers.append('x-access-token', localStorage.getItem('token'));

    let options = { headers: headers };
    let body = {
      email: email.toLowerCase(),
    };

    return this.http.post(url, body, options);
  }

  checkPasswordToken(token) {
    let url =
      this._config['settings']['db'] + '/api/authenticate/checkpasswordtoken';
    let headers = new HttpHeaders();
    headers.append('Accept', 'application/json');
    headers.append('Content-Type', 'application/json');
    headers.append('x-access-token', localStorage.getItem('token'));

    let options = { headers: headers };
    let body = {
      token: token,
    };

    return this.http.post(url, body, options);
  }

  private handleErrors(error: any) {
    console.error('Error : ' + error.message);
    return throwError(() => new Error(error || 'Server Error')); // Observable.throw(error || "Server error!");
  }
}
