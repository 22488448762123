<app-header></app-header>

<p-table
  #dt
  [value]="kados"
  dataKey="_id"
  editMode="row"
  [(selection)]="selectedKado"
>
  <ng-template pTemplate="caption">
    <div class="headerContainer">
      <div class="headerRight">
        <button
          pButton
          type="button"
          icon="pi pi-plus"
          class="ui-button-rounded ui-button-success"
          [label]="'Nieuw'"
          pAddRow
          [table]="dt"
          [newRow]="newRow()"
        ></button>
      </div>
    </div>
  </ng-template>
  <ng-template pTemplate="header">
    <tr>
      <th style="display: none"></th>

      <th style="width: 40%">Omschrijving</th>
      <th style="width: 40%">Link</th>
      <th style="width: 10em"></th>
    </tr>
  </ng-template>
  <ng-template
    pTemplate="body"
    let-kado
    let-editing="editing"
    let-ri="rowIndex"
  >
    <tr [pEditableRow]="kado">
      <td style="display: none">{{ kado._id }}</td>

      <td>
        <p-cellEditor>
          <ng-template pTemplate="input">
            <input
              pInputText
              type="text"
              [(ngModel)]="kado.omschrijving"
              style="width: 100%"
            />
          </ng-template>
          <ng-template pTemplate="output">
            {{ kado.omschrijving }}
          </ng-template>
        </p-cellEditor>
      </td>

      <td>
        <p-cellEditor>
          <ng-template pTemplate="input">
            <input
              pInputText
              type="text"
              [(ngModel)]="kado.url"
              style="width: 100%"
            />
          </ng-template>
          <ng-template pTemplate="output">
            <button
              pButton
              type="button"
              icon="pi pi-external-link"
              class="ui-button-info buttonDiv"
              (click)="openUrl(kado.url)"
            ></button>
          </ng-template>
        </p-cellEditor>
      </td>

      <td>
        <!-- <div class="buttonContainer" *ngIf="!kado.gever"> -->
        <button
          *ngIf="userService.showAdmin && kado.gever"
          pButton
          type="button"
          icon="pi pi-check"
          class="ui-button-info buttonDiv"
          (click)="showUser(kado.gever)"
        ></button>

        <div
          class="buttonContainer"
          *ngIf="
            this.vandaag < this.lockdate ||
            this.vandaag === kado.datum ||
            kado.url === ''
          "
        >
          <button
            *ngIf="!editing"
            pButton
            type="button"
            pInitEditableRow
            icon="pi pi-pencil"
            class="ui-button-info buttonDiv"
            (click)="onRowEditInit(kado)"
          ></button>
          <button
            *ngIf="!editing"
            pButton
            type="button"
            icon="pi pi-angle-double-right"
            class="ui-button-danger buttonDiv"
            (click)="maakDefinitief(kado)"
            title="Maak zichtbaar"
          ></button>
          <button
            *ngIf="editing"
            pButton
            type="button"
            pSaveEditableRow
            icon="pi pi-check"
            class="ui-button-success buttonDiv"
            style="margin-right: 0.5em"
            (click)="onRowEditSave(kado)"
          ></button>
          <button
            *ngIf="editing"
            pButton
            type="button"
            pCancelEditableRow
            icon="pi pi-times"
            class="ui-button-danger buttonDiv"
            (click)="onRowEditCancel(kado, ri)"
          ></button>
          <button
            *ngIf="editing"
            pButton
            type="button"
            icon="pi pi-trash"
            class="ui-button-danger buttonDivauto auto auto"
            (click)="onRowDelete(kado, ri)"
          ></button>
        </div>
      </td>
    </tr>
  </ng-template>
</p-table>

<p-confirmDialog
  header="Confirmation"
  icon="pi pi-exclamation-triangle"
></p-confirmDialog>

<p-toast></p-toast>
