import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { MenuItem } from 'primeng/api/menuitem';
import { ThemeService } from '../shared/theme.service';
import { UserService } from '../shared/user.service';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
})
export class HeaderComponent implements OnInit {
  items: MenuItem[];
  itemsperson: MenuItem[];
  naam = localStorage.getItem('user');
  dlgPassword = false;
  bAdmin = false;

  constructor(
    public _router: Router,
    private themeService: ThemeService,
    private userService: UserService
  ) {}

  ngOnInit(): void {
    console.log(1, localStorage.getItem('theme'));
    if (localStorage.getItem('theme') == 'undefined') {
      console.log(2);
      this.changeTheme('saga-blue');
    }
    this.items = [
      {
        label: 'Home',
        icon: 'fal fa-home',
        command: () => {
          this._router.navigate(['/main']);
        },
      },
      {
        label: 'Mijn lijstje',
        icon: 'fal fa-gifts',
        command: () => {
          this._router.navigate(['/mijnlijst']);
        },
      },
      {
        label: 'Kies',
        icon: 'fal fa-users',
        command: () => {
          this._router.navigate(['/kies']);
        },
      },
      {
        label: 'Boodschappenlijst',
        icon: 'fal fa-shopping-cart',
        command: () => {
          this._router.navigate(['/boodschappenlijst']);
        },
      },
    ];

    this.itemsperson = [
      {
        label: this.naam,
        items: [
          // {
          //   label: 'Wachtwoord aanpassen',
          //   icon: 'fal fa-key',
          //   command: () => {
          //     this.password();
          //   },
          // },
          {
            label: 'uitloggen',
            icon: 'fal fa-sign-out',
            command: () => {
              this.logout();
            },
          },
          {
            label: 'admin',
            icon: 'fal fa-sign-out',
            command: () => {},
          },
        ],
      },
      {
        label: "Thema's",
        items: [
          {
            label:
              '<img src="assets/images/themes/saga-blue.png"><span class="menu-text">saga-blue</span>',
            escape: false,
            command: () => {
              this.changeTheme('saga-blue');
            },
          },
          {
            label:
              '<img src="assets/images/themes/vela-blue.png"><span class="menu-text">vela-blue</span>',
            escape: false,
            command: () => {
              this.changeTheme('vela-blue');
            },
          },
          {
            label:
              '<img src="assets/images/themes/arya-blue.png"><span class="menu-text">arya-blue</span>',
            escape: false,
            command: () => {
              this.changeTheme('arya-blue');
            },
          },
          {
            label:
              '<img src="assets/images/themes/md-light-indigo.svg" style="width:15px"><span class="menu-text">light-indigo</span>',
            escape: false,
            command: () => {
              this.changeTheme('md-light-indigo');
            },
          },
          {
            label:
              '<img src="assets/images/themes/md-dark-indigo.svg"><span class="menu-text">dark-indigo</span>',
            escape: false,
            command: () => {
              this.changeTheme('md-dark-indigo');
            },
          },
          {
            label:
              '<img src="assets/images/themes/bootstrap4-light-purple.svg"><span class="menu-text">light-purple</span>',
            escape: false,
            command: () => {
              this.changeTheme('bootstrap4-light-purple');
            },
          },
          {
            label:
              '<img src="assets/images/themes/bootstrap4-dark-purple.svg"><span class="menu-text">dark-purple</span>',
            escape: false,
            command: () => {
              this.changeTheme('bootstrap4-dark-purple');
            },
          },
        ],
      },
    ];
  }

  logout() {
    localStorage.clear();
    this._router.navigate(['/login']);
  }

  password() {
    this.dlgPassword = true;
  }

  exitPassword() {
    this.dlgPassword = false;
  }

  changeTheme(theme: string) {
    console.log(theme);
    this.themeService.switchTheme(theme);
    const id = localStorage.getItem('id');
    this.userService.getUser(id).subscribe((user) => {
      console.log(user);
      user['theme'] = theme;
      this.userService.updateUser(id, user);
    });
  }

  changePassword() {}
}
