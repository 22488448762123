import { Component } from '@angular/core';
import { Config } from './shared/config';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent {
  config: any;

  constructor(public _config: Config) {}

  ngOnInit() {
    this.config = this._config.settings;
  }
}
