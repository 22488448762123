import { Component, OnInit, AfterViewInit } from '@angular/core';
import { Config } from '../shared/config';
import { UserService } from '../shared/user.service';
import { User } from '../shared/user';
import { Kado } from '../shared/kado';
import { KadoService } from '../shared/kado.service';
import { ConfirmationService, MessageService } from 'primeng/api';
import * as moment from 'moment';

@Component({
  selector: 'app-kies',
  templateUrl: './kies.component.html',
  styleUrls: ['./kies.component.scss'],
})
export class KiesComponent implements OnInit, AfterViewInit {
  users: User[] = [];
  kados: Kado[] = [];
  id = localStorage.getItem('id');
  selectedUser: User;
  selectedKado: Kado;
  vandaag = moment(new Date()).format('YYYYMMDD');
  lockDate = '20201101';

  constructor(
    public _config: Config,
    public _usersService: UserService,
    public _kadoService: KadoService,
    private confirmationService: ConfirmationService,
    private messageService: MessageService
  ) {}

  ngOnInit(): void {}

  ngAfterViewInit() {
    this.getOtherUsers();
  }

  getOtherUsers() {
    this._usersService.getOtherUsers(this.id).subscribe((result: User[]) => {
      this.users = result;
      for (let user of this.users) {
        this._kadoService
          .getKados(user['_id'], null)
          .subscribe((result: Kado[]) => {
            user['aantal'] = result.length;
          });
      }
    });
  }

  getKados() {
    this._kadoService
      .getKados(this.selectedUser['_id'], this.id)
      .subscribe((result: Kado[]) => {
        this.kados = result;
      });
  }

  rowSelect() {
    this.getKados();
  }

  kadoSelect(id) {
    this._kadoService
      .vulGever(id, this.id, this.selectedUser['_id'])
      .subscribe({
        next: (data) => {
          console.log(data);
          this.getKados();
          this.getOtherUsers();
        },
        error: (e) => {
          this.messageService.add({
            severity: 'error',
            summary: 'Dit kado is al geselecteerd',
          });
          // this.errorMessage = e.error.error.message;
        },
      });
    // .subscribe((result) => {
    //   console.log(result);
    //   this.getKados();
    //   this.getOtherUsers();
    // });
  }

  kadoUnselect(id) {
    this._kadoService
      .vulGever(id, undefined, this.selectedUser['_id'])
      .subscribe((result) => {
        console.log(result);
        this.getKados();
        this.getOtherUsers();
      });
  }

  kadoDelete(kado, index) {
    console.log('Delete!!:', index, kado);
    this.confirmationService.confirm({
      message: 'Weet u het zeker?',
      header: 'Bevestiging',
      icon: 'pi pi-exclamation-triangle',
      accept: () => {
        this.kados.splice(index, 1);
        this._kadoService.deleteKado(kado);
      },
      reject: () => {},
    });
  }

  showUser(key) {
    this._usersService.getUser(key).subscribe((result) => {
      this.messageService.add({
        severity: 'info',
        summary: '',
        detail: result['loginName'],
      });
      console.log(result['loginName']);
    });
  }
}
