<!-- <div class="row justify-content-center">

  <div class="col-4 align-self-center" style="margin-top:200px">
    <div class="card card-default">
      <div class="card-header card-primary">

        Login

      </div>
      <div class="card-body"> -->

<div class="login">
  <img src="/assets/images/gringe.png" style="width: 250px" />
  <form [formGroup]="myForm" (ngSubmit)="onSubmit()">
    <!-- <label for="exampleInputEmail1">Inlognaam</label> -->

    <div class="login-input">
      <span class="p-float-label">
        <input
          type="text"
          id="inputtext"
          pInputText
          formControlName="username"
          style="text-transform: lowercase"
        />
        <label for="inputtext">Email</label>
      </span>
    </div>

    <!-- <label for="exampleInputPassword1">Wachtwoord</label> -->
    <div class="login-input">
      <span class="p-float-label">
        <input
          type="password"
          id="inputtext"
          pInputText
          formControlName="password"
        />
        <label for="inputtext">Wachtwoord</label>
      </span>
    </div>

    <div>
      <button
        pButton
        type="submit"
        label="Inloggen"
        class="ui-button-rounded"
        [disabled]="!myForm.valid"
      ></button>
      <!-- <button
        pButton
        pRipple
        type="button"
        icon="pi pi-user-plus"
        class="p-button-rounded p-button-info"
        (click)="dspAanmelden = true"
        style="float: right"
        title="Aanmelden"
      ></button> -->
    </div>

    <p class="vergeten">
      <a (click)="dspWachtwoordVergeten = true">Wachtwoord vergeten</a>
    </p>
    <div class="bg-danger">
      <p-message
        severity="error"
        *ngIf="errorMessage"
        text="{{ errorMessage }}"
      ></p-message>
    </div>
  </form>
</div>
<p-toast></p-toast>

<p-dialog header="Aanmelden" [(visible)]="dspAanmelden">
  <form [formGroup]="aanmeldForm" (ngSubmit)="aanmelden()">
    <p-card [style]="{ width: '360px' }" styleClass="p-card-shadow">
      <p-header> </p-header>
      <div class="p-field p-col-12">
        <span class="p-float-label">
          <input
            id="inlognaam"
            type="text"
            pInputText
            formControlName="loginName"
          />
          <label for="inlognaam">Inlognaam</label>
        </span>
      </div>

      <div class="p-field p-col-12">
        <span class="p-float-label">
          <input id="email" type="text" pInputText formControlName="email" />
          <label for="email">Email</label>
        </span>
      </div>
      <div class="p-field p-col-12">
        <span class="p-float-label">
          <input
            id="wachtwoord"
            type="password"
            pInputText
            formControlName="password"
            autocomplete="new-password"
          />
          <label for="wachtwoord">Wachtwoord</label>
        </span>
      </div>
      <div class="p-field p-col-12">
        <span class="p-float-label">
          <input
            id="wachtwoordcontrole"
            type="password"
            pInputText
            formControlName="passwordCheck"
          />
          <label for="wachtwoordcontrole">Wachtwoord controle</label>
        </span>
      </div>

      <ng-template pTemplate="footer">
        <p-button
          label="Aanmelden"
          type="submit"
          icon="pi pi-check"
          [disabled]="!aanmeldForm.valid"
        ></p-button>
      </ng-template>
    </p-card>
  </form>
</p-dialog>

<p-dialog header="Wachtwoord vergeten" [(visible)]="dspWachtwoordVergeten">
  <p-card
    [style]="{ width: '360px' }"
    styleClass="p-card-shadow"
    subheader="Aanvragen link voor nieuw wachtwoord"
  >
    <div class="p-field p-col-12">
      <span class="p-float-label">
        <input
          id="email"
          type="text"
          style="text-transform: lowercase"
          pInputText
          [(ngModel)]="email"
        />
        <label for="email">email</label>
      </span>
    </div>

    <ng-template pTemplate="footer">
      <p-button
        label="Versturen"
        (click)="wachtwoordVergeten()"
        icon="pi pi-check"
        [disabled]="email == ''"
      ></p-button>
    </ng-template>
  </p-card>
</p-dialog>

<p-dialog
  header="Nieuw wachtwoord"
  [(visible)]="dspNieuwWachtwoord"
  modal="true"
>
  <p-card
    [style]="{ width: '360px' }"
    styleClass="p-card-shadow"
    subheader="Geef het nieuwe wachtwoord in"
  >
    <div class="login-input">
      <div class="p-field p-col-12">
        <span class="p-float-label">
          <input
            id="wachtwoord"
            type="password"
            pInputText
            [(ngModel)]="wachtwoord"
          />
          <label for="wachtwoord">Wachtwoord</label>
        </span>
      </div>
    </div>
    shared
    <div class="login-input">
      <div class="p-field p-col-12">
        <span class="p-float-label">
          <input
            id="controle"
            type="password"
            pInputText
            [(ngModel)]="controle"
          />
          <label for="controle">Controle</label>
        </span>
      </div>
    </div>

    <ng-template pTemplate="footer">
      <p-button
        label="Versturen"
        (click)="nieuwWachtwoord()"
        icon="pi pi-check"
        *ngIf="wachtwoord > '' && wachtwoord == controle"
      ></p-button>
    </ng-template>
  </p-card>
</p-dialog>
