import { Routes, RouterModule } from '@angular/router';
import { AanmeldenComponent } from './aanmelden/aanmelden.component';
import { AuthGuard } from './auth.guard';
import { BoodschappenlijstComponent } from './boodschappenlijst/boodschappenlijst.component';
import { KiesComponent } from './kies/kies.component';

import { LoginComponent } from './login/login.component';
import { MainComponent } from './main/main.component';
import { MijnlijstComponent } from './mijnlijst/mijnlijst.component';

const appRoutes: Routes = [
  { path: 'login', component: LoginComponent },
  {
    path: 'aanmelden',
    component: AanmeldenComponent,
  },
  { path: '', component: MainComponent, canActivate: [AuthGuard] },
  { path: 'main', component: MainComponent, canActivate: [AuthGuard] },
  {
    path: 'mijnlijst',
    component: MijnlijstComponent,
    canActivate: [AuthGuard],
  },
  { path: 'kies', component: KiesComponent, canActivate: [AuthGuard] },
  {
    path: 'boodschappenlijst',
    component: BoodschappenlijstComponent,
    canActivate: [AuthGuard],
  },
];

export const AppRoutingProviders: any[] = [];

export const routing = RouterModule.forRoot(appRoutes);
// export const routing = RouterModule.forRoot(appRoutes, {initialNavigation : false});
