<div class="grid flex-column min-h-screen">
  <div class="page-header"><app-header></app-header></div>
  <div class="flex flex-grow-1 h-4rem">
    <div class="col-2 overflow-scroll">
      <p-table
        [value]="users"
        selectionMode="single"
        [(selection)]="selectedUser"
        [scrollable]="true"
        (onRowSelect)="rowSelect()"
      >
        <ng-template pTemplate="caption">
          <div class="header">
            <div class="title"></div>
            <div class="toolbar"></div>
          </div>
        </ng-template>
        <ng-template pTemplate="header">
          <tr>
            <th>Naam</th>
          </tr>
        </ng-template>
        <ng-template pTemplate="body" let-rowData>
          <tr [pSelectableRow]="rowData">
            <td style="justify-content: space-between">
              <div class="flex">
                <div>
                  {{ rowData.loginName }}
                </div>
                <div class="" class="absolute md:right-0">
                  <p-badge
                    [value]="rowData.aantal"
                    styleClass="p-mr-2"
                    *ngIf="rowData.aantal > 0"
                  ></p-badge>
                </div>
              </div>
            </td>
          </tr>
        </ng-template>
      </p-table>
    </div>
    <div class="col-10 overflow-scroll">
      <p-table [value]="kados" [scrollable]="true">
        <ng-template pTemplate="caption">
          <div class="header">
            <div class="Omschrijving"></div>
          </div>
        </ng-template>
        <ng-template pTemplate="header">
          <tr>
            <th>Omschrijving</th>
            <th></th>
          </tr>
        </ng-template>
        <ng-template pTemplate="body" let-rowData let-ri="rowIndex">
          <tr
            [pSelectableRow]="rowData"
            [ngClass]="
              rowData.gever != '' &&
              rowData.gever != undefined &&
              rowData.gever == id
                ? 'selected'
                : undefined
            "
          >
            <td>
              <span *ngIf="rowData.url > ''">
                <a
                  href="{{ rowData.url }}"
                  target="_blank"
                  class="text-900 no-underline hover:surface-200"
                  >{{ rowData.omschrijving }}</a
                >
              </span>
              <span *ngIf="rowData.url === undefined || rowData.url === ''">
                {{ rowData.omschrijving }}
              </span>
            </td>
            <td class="flex" style="width: 11em">
              <button
                *ngIf="
                  this.vandaag >= this.lockDate &&
                  (rowData.gever === '' || rowData.gever === undefined)
                "
                pButton
                pRipple
                type="button"
                label="Kies deze"
                class="p-button-rounded"
                (click)="kadoSelect(rowData._id)"
              ></button>
              <button
                *ngIf="rowData.gever == id"
                [disabled]="rowData.gekocht"
                pButton
                pRipple
                type="button"
                label="Toch niet"
                class="p-button-rounded"
                (click)="kadoUnselect(rowData._id)"
              ></button>
              <button
                *ngIf="
                  rowData.gever != '' &&
                  rowData.gever != undefined &&
                  rowData.gever != id &&
                  _usersService.showAdmin
                "
                pButton
                pRipple
                type="button"
                label="Geselecteerd"
                class="p-button-rounded"
                (click)="showUser(rowData.gever)"
              ></button>
              <div *ngIf="_usersService.showAdmin">
                <button
                  pButton
                  pRipple
                  type="button"
                  icon="pi pi-trash"
                  class="p-button-rounded"
                  (click)="kadoDelete(rowData, ri)"
                ></button>
              </div>
            </td>
          </tr>
        </ng-template>
      </p-table>
    </div>
  </div>
  <div class="page-footer">3</div>
</div>

<div *ngIf="false" class="grid h-screen" style="border: 1px solid white">
  <div class="col-12 h-4rem" style="border: 1px solid yellow">
    <app-header></app-header>
  </div>
  <div class="col-2 h-full" style="border: 1px solid green">
    <p-table
      [value]="users"
      selectionMode="single"
      [(selection)]="selectedUser"
      [scrollable]="true"
      scrollHeight="95%"
      (onRowSelect)="rowSelect()"
    >
      <ng-template pTemplate="caption">
        <div class="header">
          <div class="title"></div>
          <div class="toolbar"></div>
        </div>
      </ng-template>
      <ng-template pTemplate="header">
        <tr>
          <th>Naam</th>
        </tr>
      </ng-template>
      <ng-template pTemplate="body" let-rowData>
        <tr [pSelectableRow]="rowData">
          <td style="justify-content: space-between">
            <div class="flex">
              <div>
                {{ rowData.loginName }}
              </div>
              <div class="" class="absolute md:right-0">
                <p-badge
                  [value]="rowData.aantal"
                  styleClass="p-mr-2"
                  *ngIf="rowData.aantal > 0"
                ></p-badge>
              </div>
            </div>
          </td>
        </tr>
      </ng-template>
    </p-table>
  </div>
  <div class="col-10 h-full" style="border: 1px solid blue">
    <p-table [value]="kados" [scrollable]="true">
      <ng-template pTemplate="caption">
        <div class="header">
          <div class="Omschrijving"></div>
        </div>
      </ng-template>
      <ng-template pTemplate="header">
        <tr>
          <th>Omschrijving</th>
          <th></th>
        </tr>
      </ng-template>
      <ng-template pTemplate="body" let-rowData let-ri="rowIndex">
        <tr
          [pSelectableRow]="rowData"
          [ngClass]="
            rowData.gever != '' && rowData.gever != undefined
              ? 'selected'
              : undefined
          "
        >
          <td>
            <span *ngIf="rowData.url > ''">
              <a
                href="{{ rowData.url }}"
                target="_blank"
                class="text-900 no-underline hover:surface-200"
                >{{ rowData.omschrijving }}</a
              >
            </span>
            <span *ngIf="rowData.url === undefined || rowData.url === ''">
              {{ rowData.omschrijving }}
            </span>
          </td>
          <td class="flex" style="width: 11em">
            <button
              *ngIf="
                this.vandaag >= this.lockDate &&
                (rowData.gever === '' || rowData.gever === undefined)
              "
              pButton
              pRipple
              type="button"
              label="Kies deze"
              class="p-button-rounded"
              (click)="kadoSelect(rowData._id)"
            ></button>
            <button
              *ngIf="rowData.gever > ''"
              [disabled]="rowData.gekocht"
              pButton
              pRipple
              type="button"
              label="Toch niet"
              class="p-button-rounded"
              (click)="kadoUnselect(rowData._id)"
            ></button>
            <div *ngIf="_usersService.showAdmin">
              <button
                pButton
                pRipple
                type="button"
                icon="pi pi-trash"
                class="p-button-rounded"
                (click)="kadoDelete(rowData, ri)"
              ></button>
            </div>
          </td>
        </tr>
      </ng-template>
    </p-table>
  </div>
</div>

<p-confirmDialog
  header="Confirmation"
  icon="pi pi-exclamation-triangle"
></p-confirmDialog>

<p-toast></p-toast>
