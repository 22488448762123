import { Injectable } from '@angular/core';
import { Observable, throwError } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { tap, catchError } from 'rxjs/operators';

@Injectable()
export class Config {
  public settings: Object;

  constructor(private http: HttpClient) {}

  load() {
    console.log('load config constructor');

    // return new Promise((resolve) => {

    return this.http.get('assets/config.json').pipe(
      tap((config) => {
        console.log('Configuration loaded...........');
        this.settings = config;
        // resolve();
      }),
      catchError(this.handleError)
    );

    // });

    // return this.http.get('./config.json')
    //     .map(res => res.json())
    //     //tap(result => this.settings = result)
    //     catchError(this.handleError)
  }

  get(key: any) {
    return this.settings[key];
  }

  private handleError(error: any) {
    console.error('Error : ' + error.message);
    return throwError(() => new Error(error || 'Server Error')); // Observable.throw(error || "Server error!");
  }
}
