import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
// import 'rxjs/rx';
import { Config } from './config';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { catchError, tap } from 'rxjs/operators';
import { UserService } from './user.service';

@Injectable()
export class KadoService {
  kados: any;
  handleError(arg0: any): any {
    // tslint:disable-next-line:quotemark
    throw new Error('Method not implemented.');
  }

  headers = new HttpHeaders({
    Accept: 'application/json',
    'Content-Type': 'application/json',
    'x-access-token': localStorage.getItem('token'),
  });

  constructor(
    public _config: Config,
    public http: HttpClient,
    private userService: UserService
  ) {}

  getKados(aanvrager_id, gever_id) {
    console.log('Get kados');
    let url =
      this._config['settings']['db'] +
      '/api/kados?aanvrager_id=' +
      aanvrager_id +
      '&gever_id=' +
      gever_id +
      '&admin=' +
      this.userService.showAdmin;

    let options = { headers: this.headers };
    return this.http.get(url, options).pipe(
      tap((data) => {}),
      catchError(this.handleError)
    );
  }

  getBoodschappen(gever_id) {
    let url =
      this._config['settings']['db'] +
      '/api/kados?aanvrager_id=' +
      '' +
      '&gever_id=' +
      gever_id;

    let options = { headers: this.headers };
    return this.http.get(url, options).pipe(
      tap((data) => {}),
      catchError(this.handleError)
    );
  }

  addKado(kado) {
    console.log('Add new kado', kado);
    let url = this._config['settings']['db'] + '/api/kados';

    let options = { headers: this.headers };
    let body = kado;

    return this.http.post(url, body, options);
  }

  getKado(key) {
    let url = this._config['settings']['db'] + '/api/kados/kado/' + key;
    let headers = new HttpHeaders();
    headers.append('Accept', 'application/json');
    headers.append('Content-Type', 'application/json');
    headers.append('x-access-token', localStorage.getItem('token'));

    let options = { headers: headers };

    return this.http.get(url, options);
    catchError(this.handleError);
  }

  updateKado(kado) {
    console.log('Update kado');
    let url = this._config['settings']['db'] + '/api/kados';

    let options = { headers: this.headers };
    let body = kado;

    return this.http.patch(url, body, options);
  }

  deleteKado(kado) {
    console.log('Verwijder kado');
    let url = this._config['settings']['db'] + '/api/kados/' + kado._id;
    // this.kados.splice(this.kados.indexOf(kado), 1);
    let headers = new HttpHeaders();
    headers.append('Accept', 'application/json');
    headers.append('Content-Type', 'application/json');
    headers.append('x-access-token', localStorage.getItem('token'));

    let options = { headers: headers };
    // let body = JSON.stringify(kado);

    this.http.delete(url, options).subscribe((result) => {});
  }

  vulGever(kado_id, gever_id, aanvrager_id) {
    console.log('Update kado');
    let url = this._config['settings']['db'] + '/api/kados/vulgever';

    let options = { headers: this.headers };
    let body = { _id: kado_id, gever_id: gever_id, aanvrager_id: aanvrager_id };

    return this.http.patch(url, body, options);
  }

  vulGekocht(kado_id) {
    let url = this._config['settings']['db'] + '/api/kados/gekocht';

    let options = { headers: this.headers };
    let body = { _id: kado_id };

    return this.http.patch(url, body, options);
  }
}
