import { Component, OnInit } from '@angular/core';
import { ConfirmationService, MessageService } from 'primeng/api';
import { Kado } from '../shared/kado';
import { KadoService } from '../shared/kado.service';
import { UserService } from '../shared/user.service';
import * as moment from 'moment';

@Component({
  selector: 'app-mijnlijst',
  templateUrl: './mijnlijst.component.html',
  styleUrls: ['./mijnlijst.component.scss'],
})
export class MijnlijstComponent implements OnInit {
  kados: Kado[] = [];
  selectedKado: Kado;
  id = localStorage.getItem('id');
  vandaag = moment(new Date()).format('YYYYMMDD');
  lockdate = '20201101';

  constructor(
    public _kadoService: KadoService,
    private confirmationService: ConfirmationService,
    public userService: UserService,
    private messageService: MessageService
  ) {}

  ngOnInit() {
    console.log('init');
    this.getKados();
  }

  getKados() {
    this._kadoService.getKados(this.id, '').subscribe((result: Kado[]) => {
      console.log(result);
      this.kados = result;
    });
  }

  rowSelect() {}

  onRowEditInit(kado: Kado) {
    console.log('Init:', kado);
  }

  onRowEditSave(kado: Kado) {
    console.log('Save:', kado);
    if (kado['_id'] > '') {
      this._kadoService.updateKado(kado).subscribe();
    } else {
      kado.aanvrager = localStorage.getItem('id');
      kado.naam = localStorage.getItem('user');
      kado.datum = moment(new Date()).format('YYYYMMDD');
      this._kadoService.addKado(kado).subscribe();
      this.getKados();
      this.selectedKado = null;
    }
  }

  onRowEditCancel(kado: Kado, index: number) {
    console.log('Cancel:', kado);
    this.getKados();
  }

  onRowDelete(kado: Kado, index: number) {
    console.log('Delete:', index, Kado);
    this.confirmationService.confirm({
      message: 'Weet je zeker dat je dit kado wilt verwijderen?',
      header: 'Bevestiging',
      icon: 'pi pi-exclamation-triangle',
      accept: () => {
        this.kados.splice(index, 1);
        this._kadoService.deleteKado(kado);
      },
      reject: () => {},
    });
  }

  maakDefinitief(kado: Kado) {
    this.confirmationService.confirm({
      message:
        'Weet je zeker dat je het kado definitief door wil sturen? Het kan dan niet meer gewijzigd worden.',
      header: 'Bevestiging',
      icon: 'pi pi-exclamation-triangle',
      accept: () => {
        kado.datum = moment(new Date()).subtract(1, 'days').format('YYYYMMDD');
        this._kadoService.updateKado(kado).subscribe((result) => {
          this.getKados();
        });
      },
      reject: () => {},
    });
  }

  newRow() {
    // console.log('nieuw');
    // let nk = new Kado();
    // this.kados.push(nk);
    return { datum: moment(new Date()).format('YYYYMMDD') };
  }

  openUrl(url) {
    window.open(url, '_blank');
  }

  showUser(key) {
    this.userService.getUser(key).subscribe((result) => {
      this.messageService.add({
        severity: 'info',
        summary: '',
        detail: result['loginName'],
      });
      console.log(result['loginName']);
    });
  }
}
